<template>
  <div class="container d-flex jc-center ai-center text-dark-1"> 
      <div class="form-wrap p-30">
          <div>
              <label for="username"> <strong>用户名:</strong> </label>
              <input maxlength="12" :class="{active: model.username}" type="text" v-model= "model.username" id="username">
          </div>
          <div class="mt-20"> 
              <label for="password"> <strong>密码:</strong> </label>
              <input maxlength="12" :class="{active: model.password.length > 4}" type="password" v-model= "model.password" id="password"  @keypress.enter="logIn">
          </div>
          <div class="mt-20" v-if="registerBoolean"> 
              <label for="password2"> <strong>再次输入密码:</strong> </label>
              <input :class="{active: model.password == model.password2}" type="password" v-model= "model.password2" id="password2">
          </div>
          <div class="mb-30">
              <p>1. 密码长度需要大于5位</p>
              <p>2. 注册 》 再次输入密码 》 注册，即可注册</p>
              <p>3. 测试账号：admin 密码：admin ，请勿私用</p>
          </div>
          <p class="message" >{{message}}</p>
          <div class="d-flex button-wrap jc-between p-20">
              <div class="button m-c-p" :class="{ active: verifyPass }" @click="register"> 注册 </div>
              <div class="button m-c-p" @click="logIn"> <strong>登录</strong> </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            registerBoolean: false, 
            message: '',
            model: {
                username: '',
                password: '',
            }
        }
    },
    methods:{
        async send(){
            const result = await this.$axios.get('/')
        },

        // 注册
        async register(){
            if(!this.registerBoolean){
                return this.registerBoolean = true
            }else{
                const result = await this.$axios.post('register', this.model)
                this.message = result.data.message
                if(result.data.message == "注册成功"){
                    // 设置vuex的username属性值
                    // 设置token到本地
                    this.$store.commit('userInfo', [this.model, 'username'])
                    localStorage.token = result.data.token
                    this.$router.push('/')
                }
            }
        },

        // 登录
        async logIn(){
            if(!this.model.username){
                return this.message = '请输入用户名'
            }
            if(!this.model.password){
                return this.message = '请输入密码'
            }
            const result = await this.$axios.post('login', this.model)
            this.message = result.data.message
            if(result.data.message == "登录成功"){
                // 设置vuex的username属性值
                // 设置token到本地
                this.$store.commit('userInfo', [this.model, 'username'])
                this.$store.commit('loginState', true)
                localStorage.token = result.data.token
                this.$router.push('/')
            }
        }
    },
    computed: {
        verifyPass(){
            if(this.model.username && this.model.password.length > 4 && this.model.password == this.model.password2){
                return true
            }
            return false
        }
    },
    mounted(){
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
.container{
    width: 100vw;
    height: 80vh;
    .form-wrap{
        width: 400px;
        border: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.18);
        font-size: 14px;
        &.active{
            margin-top: 200px;
        }
        label{
            display: block;
            margin-bottom: 15px;
        }
        input{
            width: 100%;
            height: 40px;
            border: 1px solid #990000;
            border-radius: 5px;
            padding-left: 10px;
            &.active{
                border-color: map-get($colors, 'purple');
            }
        }

        .message{
            color: #990000;
            text-align: center;
        }

        .button-wrap{
            width: 270px;
            margin: 0 auto;
            font-size: 16px;
            .button{
                width: 90px;
                height: 40px;
                background: map-get($colors, 'purple');
                text-align: center;
                line-height: 40px;
                color: white;
                border-radius: 5px;
            }
            .button:nth-of-type(1){
                background: transparent;
                border: 1px solid #ccc;
                color: #222;
                &.active{
                    border: none;
                    background: map-get($colors, 'success');
                    color: map-get($colors, 'white');
                }
            }
        }
    }

}

</style>